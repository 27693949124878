.category-main {
  width: 100%;
  background-color: white;
  .category-hero-img-section {
    background-image: url("../../assets/homehero.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .category-main-hero-content-section {
      padding: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      .category-content {
        padding-top: 3rem;
        font-size: 4rem;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          text-align: center;
          padding-top: 3rem;
        }
      }
    }
  }
  .logo-carousel {
    .owl-carousel {
      .owl-item {
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: center;
      }
    }
  }

  .category-card-outer {
    width: 90%;
    margin: auto;
    padding-bottom: 2%;
    padding-top: 2%;
    row-gap: 10px;

    .category-card-inner {
      padding: 3rem;
      --bs-gutter-x: 0;
      margin-left: 0 !important;
      background-color: #f0f0f0;
      border-radius: 12px;
      width: 100%;
      .category-card-section-1 {
        cursor: pointer;
        padding: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        .category-card-big {
          height: 100%;
          margin-left: 0 !important;
          background-color: white;
          border-radius: 12px;
          width: 100%;
          padding: 2%;

          .card-image {
            padding: 0;
            background-color: #f0f0f0;

            .card-image-outer {
              border-radius: 12px;
              position: relative;
              height: 100%;
              .card-color {
                position: absolute;
                top: 40%;
                left: 10px;
                .color-outer {
                  padding: 6px;
                  .color1 {
                    width: 15px;
                    height: 15px;
                    background-color: pink;
                    border-radius: 15px;
                  }
                }
              }
              .card-image-just-outer {
                height: 100%;
                display: flex;
                align-items: center;
                img {
                  max-width: 155%;
                  height: 90%;
                  z-index: 2;
                }
              }
            }
          }
          .card-about {
            .more-div-button {
              margin-left: 0 !important;
            }
            h4 {
              text-transform: capitalize;
              text-align: right;
              padding-top: 6%;
              padding-bottom: 6%;
            }
            p {
              text-align: right;
              position: relative;
              z-index: 5;
              width: 56%;
              float: right;
            }
            .more-div-button {
              margin-left: auto;
              width: 100%;
              float: right;
              .view-more-button {
                z-index: 10;
                margin-left: auto;
                width: fit-content;
                position: relative;
                border: none;
                background-image: linear-gradient(
                  -79deg,
                  #351f99 0.36%,
                  #0099e5 117.12%
                );
                border-radius: 7px;
                padding: 9px;
                color: white;
                width: 7rem;
                float: right;
              }
            }
          }
        }
      }
      .category-card-section-2 {
        padding: 1%;
        .category-card-section-2-outer {
          background-color: #f0f0f0;
          border-radius: 12px;
          row-gap: 10px;
          height: 100%;
          .category-card-small-1 {
            cursor: pointer;
            width: 100%;
            .category-card-small-1-inner {
              margin: 0;
              background-color: white;
              border-radius: 12px;
              padding: 2%;
              height: 100%;
              .small-image-1 {
                display: flex;
                align-items: center;
                background-color: #f0f0f0;
                border-radius: 12px;
                height: 100%;
                position: relative;
                .card-color {
                  position: absolute;
                  top: 40%;
                  left: 20px;
                  .color-outer {
                    padding: 6px;
                    .color1 {
                      width: 15px;
                      height: 15px;
                      background-color: pink;
                      border-radius: 15px;
                    }
                  }
                }
                img {
                  max-width: 100%;
                }
              }
              .small-about-1 {
                .small-about-width-1 {
                  width: 75%;
                  margin-left: auto;
                  h4 {
                    text-align: right;
                    padding-top: 1%;
                    padding-bottom: 1%;
                  }
                  p {
                    text-align: right;
                    font-size: 14px;
                    margin: 0;
                  }
                }
                .more-div-button {
                  margin-left: auto;
                  width: fit-content;
                  .view-more-button {
                    margin-top: 11px;
                    z-index: 10;
                    margin-left: auto;
                    width: fit-content;
                    position: relative;
                    border: none;
                    background-image: linear-gradient(
                      -79deg,
                      #351f99 0.36%,
                      #0099e5 117.12%
                    );
                    border-radius: 7px;
                    padding: 9px;
                    color: white;
                    width: 7rem;
                  }
                }
              }
            }
          }
          .category-card-small-2 {
            cursor: pointer;
            width: 100%;
            .category-card-small-2-inner {
              margin: 0;
              background-color: white;
              border-radius: 12px;
              padding: 2%;
              height: 100%;
              .small-image-2 {
                display: flex;
                align-items: center;
                background-color: #f0f0f0;
                border-radius: 12px;
                height: 100%;
                position: relative;
                .card-color {
                  position: absolute;
                  top: 40%;
                  left: 20px;
                  .color-outer {
                    padding: 6px;
                    .color1 {
                      width: 15px;
                      height: 15px;
                      background-color: pink;
                      border-radius: 15px;
                    }
                  }
                }
                img {
                  max-width: 100%;
                }
              }
              .small-about-2 {
                .small-about-width-2 {
                  width: 75%;
                  margin-left: auto;
                  h4 {
                    text-align: right;
                    padding-top: 1%;
                    padding-bottom: 1%;
                  }
                  p {
                    text-align: right;
                    font-size: 14px;
                    margin: 0;
                  }
                }
                .more-div-button {
                  margin-left: auto;
                  width: fit-content;
                  .view-more-button {
                    float: right;
                    margin-top: 11px;
                    z-index: 10;
                    margin-left: auto;
                    width: fit-content;
                    position: relative;
                    border: none;
                    background-image: linear-gradient(
                      -79deg,
                      #351f99 0.36%,
                      #0099e5 117.12%
                    );
                    border-radius: 7px;
                    padding: 9px;
                    color: white;
                    width: 7rem;
                  }
                  button {
                    float: right;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(odd) {
        flex-direction: row;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }
  }

  .last-section {
    padding: 80px;
    .signature-img {
      background-image: url("../../assets/category-img.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 95%;
      height: 30rem;
      margin: auto;
      border-radius: 50px;
      .signature-container {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50px;
        display: flex;
        align-items: center;
        height: 100%;
        .signature-content {
          color: white;
          font-weight: 700;
          font-size: 4rem;
          width: 30%;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .category-main {
    .category-hero-img-section {
      .category-main-hero-content-section {
        .category-content {
          text-align: center;
          font-size: 54px;
        }
      }
    }
    .logo-carousel {
      .owl-carousel {
        .owl-item {
          padding: 10px;
        }
      }
    }
    .category-card-outer {
      row-gap: 0;
      padding-top: 0;
      width: 90%;
      .category-card-inner {
        border-radius: 2px;

        padding: 0rem;
        padding-left: 2rem;

        padding-right: 2rem;
        .category-card-section-1 {
          justify-content: start;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          .category-card-big {
            padding: 3%;
            .card-image {
              .card-image-outer {
                .card-image-just-outer {
                  img {
                    padding: 4%;
                    max-width: 70%;
                    height: 100%;
                    z-index: 2;
                    margin: auto;
                  }
                }
                .card-color {
                  .color-outer {
                    .color1 {
                      width: 12px;
                      height: 12px;
                      background-color: pink;
                      border-radius: 12px;
                    }
                  }
                }
              }
            }
            .card-about {
              margin: 0;
              padding: 0;
              .more-div-button {
                margin-left: 0 !important;
                float: left;
                width: auto;
                .view-more-button {
                  margin-top: 0px !important;
                }
              }

              h4 {
                text-align: start;
                padding: 0;
                padding-top: 0.7rem;
                font-size: 18px;
              }
              p {
                text-align: justify;
                font-size: 12px;
                width: 100%;
              }
            }
          }
        }
        .category-card-section-2 {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          .category-card-section-2-outer {
            --bs-gutter-x: 0;
            row-gap: 1rem;
            .category-card-small-1 {
              .category-card-small-1-inner {
                --bs-gutter-x: 0rem;
                padding: 3%;
                .small-image-1 {
                  height: auto;
                  display: flex;
                  justify-content: space-between;
                  .card-color {
                    .color-outer {
                      .color1 {
                        width: 12px;
                        height: 12px;
                        background-color: pink;
                        border-radius: 12px;
                      }
                    }
                  }
                  img {
                    padding: 4%;
                    max-width: 70%;
                    height: 100%;
                    z-index: 2;
                    margin: auto;
                  }
                  .more-div-button {
                    .view-more-button {
                      margin-top: 0;
                    }
                  }
                }
                .small-about-1 {
                  .more-div-button {
                    margin-left: 0 !important;
                    button {
                      padding: 0;
                      margin-top: 0;
                    }
                  }
                  .small-about-width-1 {
                    width: 100% !important;
                    h4 {
                      text-align: start !important;
                      font-size: 18px;
                    }
                    p {
                      font-size: 12px !important;
                      text-align: justify !important;
                      margin-bottom: 1rem !important;
                    }
                  }
                }
              }
            }
            .category-card-small-2 {
              .category-card-small-2-inner {
                padding: 3%;
                .small-image-2 {
                  height: auto;
                  display: flex;
                  justify-content: space-between;
                  .card-color {
                    .color-outer {
                      .color1 {
                        width: 12px;
                        height: 12px;
                        background-color: pink;
                        border-radius: 12px;
                      }
                    }
                  }
                  img {
                    padding: 4%;
                    max-width: 70%;
                    height: 100%;
                    z-index: 2;
                    margin: auto;
                  }
                }
                .small-about-2 {
                  padding: 0;
                  .more-div-button {
                    margin-left: 0 !important;
                    .view-more-button {
                      margin-top: 0;
                    }
                  }
                  .small-about-width-2 {
                    width: 100% !important;
                    h4 {
                      text-align: start !important;
                      font-size: 18px;
                    }
                    p {
                      font-size: 12px !important;
                      text-align: justify !important;
                      margin-bottom: 1rem !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .last-section {
      width: 95%;
      margin: auto;
      padding: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .signature-img {
        height: 11rem;
        border-radius: 5px;
        .signature-container {
          border-radius: 5px;

          .signature-content {
            width: 80%;
            font-size: 2rem;
          }
        }
      }
    }
  }
}
