.about-wrapper {
  background-color: #f0f0f0;
  .about-hero-img-section {
    background-image: url("../../assets/video.gif");
    z-index: 1;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(#000, #100051);
      // background-color: #000000be;
      opacity: 0.7;
      z-index: 0;
    }

    .about-main-hero-content-section {
      position: relative;
      z-index: 99;
      padding: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      .about-content {
        padding-top: 3rem;
        font-size: 4rem;
        font-weight: 700;
      }
      // .home-about {
      //   display: flex;
      //   flex-direction: row;
      //   font-size: 2rem;
      //   .dot {
      //     color: white;
      //     border-radius: 1px;
      //     border: 1px solid;
      //     width: 7px;
      //     height: 7px;
      //     background-color: white;
      //     margin-top: 25px;
      //     margin-right: 15px;
      //   }
      // }
    }
    .about-hero-img-section::before {
      content: "";
      position: fixed; /* Fixed position to mimic background-attachment: fixed; */
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/video.gif");
      background-size: cover;
      background-position: center;
      z-index: -1; /* Ensure the background is behind the content */
    }
  }
  .about-wrapper-section-part-2 {
    margin: auto;
    width: 85%;
    .inner-container {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 40px;
      .left-section-wrapper {
        //   width: 30%;

        .colgapping {
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
          height: 100%;
          background-color: white;
          border-radius: 10px;
          padding: 2rem 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            text-align: justify;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
      .left-section-wrapper-content {
        .left-section-content {
          font-size: 3rem;
          font-weight: 700;
          color: black;
          padding-bottom: 1rem;
        }
      }
      .left-section-wrapper-paragraph {
        .left-section-paracontent {
          line-height: 30px;
          width: 100%;
          font-size: 1.2rem;
          word-spacing: 0.3rem;
          // padding-left: 2rem;
        }
      }
      .right-top-section {
        height: 100%;
        .colgapping {
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);

          background-color: white;
          border-radius: 10px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            text-align: justify;
          }
        }
        .right-section-img {
          max-width: 100%;

          .right-section-people-img {
            max-width: 100%;
            border-radius: 10px;
          }
        }
      }
      .right-section-wrapper {
        box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);

        margin-top: 20px;
        max-width: 100%;
        border-radius: 20px;
        padding: 12px;
        background-color: white;
        .right-section-lower-wrapper {
          padding: 15px;
          .right-subsection-part2 {
            max-width: 100%;
            border-radius: 10px;
            background-color: #f0f0f0;
            padding: 15px;
            height: 100%;
            .right-subsection-total-review {
              .subsection-value {
                background: linear-gradient(53.66deg, #29a38e 0%, #77c061 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
              }
            }
            .right-subsection-positive-review {
              .subsection-positive {
                font-weight: 700;
              }
            }
            .right-subsection-para-data {
              p {
                text-align: justify;
              }
              .subsection-para-data {
                font-size: 15px;
              }
            }
          }
          .section-subsection-content {
            padding: 0px;
            height: 100%;
            .right-subsection {
              padding: 15px;
              max-width: 100%;
              border-radius: 10px;
              background-color: #f0f0f0;
              .subsection-number-wrapper {
                .subsection-exp {
                  background-image: linear-gradient(
                    56.47deg,
                    #e96701 0%,
                    #faa805 100%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  -moz-background-clip: text;
                  -moz-text-fill-color: transparent;
                }
              }
              .subsection-content-wrapper {
                .subsection-content {
                  font-weight: 700;
                }
              }
              .subsection-para-wrapper {
                .subsection-para {
                  font-size: 15px;
                  text-align: justify;
                }
              }
            }
          }
          .right-right-about {
            height: 100%;
            .right-right-section-wrapper {
              padding: 15px;
              max-width: 100%;
              border-radius: 10px;
              background-color: #f0f0f0;
              height: 100%;
              .right-section-client {
                .right-count-client {
                  background: linear-gradient(
                    45deg,
                    #0f2699 19.79%,
                    #00aaff 84.88%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  -moz-background-clip: text;
                  -moz-text-fill-color: transparent;
                  font-size: 27;
                }
              }
              .right-subsection-trusted {
                .stop-shop {
                  font-weight: 700;
                  font-size: 15px;
                }
              }
              .right-subsection-paragraphContent {
                .right-para-content {
                  font-size: 15px;
                  text-align: justify;
                  // padding: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
  .about-section-container-process {
    background-color: white;
    padding-bottom: 40px;
    .process-focus-section {
      .process-focus-container {
        .process-focus-heading {
          padding: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
        }
      }
      .process-para-container {
        display: flex;
        justify-content: center;
        width: 80%;
        margin: auto;
        padding-bottom: 2rem;
        p {
          text-align: justify;
        }
        .process-paragraph {
          color: #666666;
          line-height: 30px;
          font-size: 18px;
        }
      }
    }

    .about-grid-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .grid-wrapper-1 {
        width: 80%;
        margin: auto;
        padding-bottom: 20px;
        .grid-section-1 {
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
          background-color: #f0f0f0;
          border-radius: 10px;
          height: 100%;
          padding: 2rem 1.5rem;
          display: flex;
          justify-content: center;
          .vision-title {
            padding-bottom: 20px;
            .vision-content {
              color: #1a8cff;
              font-size: 34px;
              font-weight: 700;
            }
          }
          .vision-para-container {
            .vision-para {
              margin: 0;
              font-size: 21px;
              font-weight: 300;
              text-align: justify;
            }
            .vision-para2 {
            }
          }
        }
        .grid-section-2 {
          justify-content: center;
          display: flex;
          align-items: stretch;
          .grid-right-img {
            width: 100%;
            .vision-img {
              height: 100%;
              border-radius: 25px;
              width: 100%;
            }
          }
        }
      }
      .grid-wrapper-2 {
        width: 80%;
        margin: auto;
        .grid-section-3 {
          border-radius: 25px;

          .grid-right-img1 {
            // width: 80%;
            // margin: auto;
            border-radius: 25px;

            height: 100%;
            .mission-img {
              width: 100%;
              height: 100%;
              border-radius: 25px;
            }
          }
        }
        .grid-section-4 {
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
          background-color: #f0f0f0;
          border-radius: 10px;
          height: 100%;
          padding: 2rem 1.5rem;
          display: flex;
          justify-content: center;uni
          .mission-title {
            padding-bottom: 20px;
            .mission-content {
              color: #1a8cff;
              font-size: 34px;
              font-weight: 700;
            }
          }
          .mission-para-container {
            .mission-para {
              font-size: 21px;
              font-weight: 300;
              text-align: justify;
              margin: 0;
            }
            .mission-para2 {
              text-align: justify;
            }
          }
        }
      }
    }
  }
  .contact {
    padding-top: 2rem;
    position: relative;
    .container-main {
      height: 100%;
      width: 84%;
      // background-image: -webkit-linear-gradient(360deg, #0f269976 19.79%, #00aaff52 84.88%);
      // position: absolute;
      margin: auto;
      margin-bottom: -4rem;
      box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      padding: 3% 3%;
      border-radius: 20px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .text {
        color: black;
        font-weight: bold;
        // position: relative;
        height: 100%;
        h2 {
          padding: 8px;
          width: 90%;
          text-align: left;
          font-family: inherit;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );
          font-size: 55px;
          font-weight: bold;
          padding-left: 20px;
          position: relative;
          &::after {
            height: 80%;
            width: 10px;
            content: "";
            left: 0;
            top: 50%;
            transform: translateX(-15%);
transform: translateY(-50%);
            background-color: #100051;
            position: absolute;
          }
        }
        // .circle{
        //   width: 100px;
        //   height: 100px;
        //   background-color: #100051;
        //   border-radius: 20px 0% 150% 00px;
        //   position: absolute;
        //   top: 0;
        //   left: 0;

        // }
        p {
          width: 83%;
          font-weight: 100;
        }
      }
      .contact-form {
        padding: 3rem 30px;
        margin: auto;
        background-color: #b9cdda58;
        box-shadow: 0px 0px 40px 0px #00000057;
        border-radius: 5px;
        border: 0.5px solid rgba(0, 0, 0, 0.108);
        gap: 10px;
        display: flex;
        flex-direction: column;

        .name {
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          .userName {
            width: 100%;
            padding: 7px;
            border: none;
            border-left: #0c43b0 solid 5px;

            margin: auto;
          }
        }
        .button {
          width: 100%;
          margin: auto;
          padding-top: 1rem;
          .learn-more {
            box-shadow: 0 0 10px 4px #ffffff4d;

            width: 100%;
            font-weight: 400;
            font-size: 18px;
            margin: auto;
            display: flex;
            /* align-items: center; */
            justify-content: center;
            border: none;
            background-image: linear-gradient(
              -79deg,
              #ffaa00 0.36%,
              #e57301 117.12%
            );
            border-radius: 7px;
            padding: 4px;
            color: white;
          }
        }
      }
    }
  }
  .footer {
    padding-top: 7%;
  }
}

@media (max-width: 767px) {
  .about-wrapper {
    .about-hero-img-section {
      background-size: cover;
      background-position: center 5%;
      .about-main-hero-content-section {
        padding: 10%;
        .about-content {
          text-align: center;
          font-size: 3.5rem !important;
        }
      }
    }
    .about-wrapper-section-part-2 {
      width: 85%;
      .inner-container {
        .row {
          row-gap: 10px;
          --bs-gutter-y: 0;
          --bs-gutter-x: 0;
          flex-direction: column-reverse;

          .left-section-wrapper-paragraph {
            .left-section-paracontent {
              font-size: 1rem;
            }
          }
          .left-section-wrapper {
            .colgapping {
              padding: 1.5rem;
              height: auto;
              p {
                text-align: justify;
                line-height: 25px;
              }
            }
          }
          .right-section-wrapper {
            .right-section-lower-wrapper {
              row-gap: 10px;
              padding: 0;
            }
          }
          .right-top-section {
            height: auto;
            .colgapping {
              height: auto;
              p {
                text-align: justify;
              }
            }
            .right-section-img {
              .right-section-people-img {
                border-radius: 10px;
              }
            }
          }
        }
        .right-section-wrapper {
          .right-section-lower-wrapper {
            padding: 0;
          }
        }
      }
    }
    .about-section-container-process {
      .process-focus-section {
        width: 80%;
        margin: auto;
        .process-focus-container {
          .process-focus-heading {
            padding: 0;
            padding-top: 2rem;
            padding-bottom: 1rem;
            justify-content: flex-start;
            text-align: center;
          }
        }
        .process-para-container {
          padding-bottom: 0;
          width: 100%;

          .process-paragraph {
            font-size: 14px;
          }
        }
      }
      .about-grid-container {
        --bs-gutter-x: 0rem;
        .grid-wrapper-1 {
          width: 100%;
          flex-direction: column-reverse;
          .grid-section-1 {
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 20px;
            .vision-para-container {
              .vision-para {
                font-size: 18px;
              }
            }
            .grid-right-img {
              height: 100%;
              img {
                height: 100%;
              }
            }
            p {
              font-size: 18px;
              text-align: justify;
            }
          }
          .grid-section-2 {
            .grid-right-img {
              margin: auto;
              border-radius: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .grid-wrapper-2 {
          width: 100%;
          .grid-section-4 {
            padding: 20px;
            margin-top: 20px;
            .mission-para-container {
              .mission-para {
                font-size: 18px;
              }
            }
            p {
              font-size: 18px;
              text-align: justify;
            }
          }
          .grid-section-3 {
            .grid-right-img1 {
              .mission-img {
                margin: auto;
                border-radius: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
    .contact {
      .container-main {
        .text {
          padding: 0;
          h2 {
            width: 100%;
            text-align: center;
            font-size: 30px;
          }
        }
        .contact-form {
          width: 100%;
          .name {
            .userName {
              width: 100%;
            }
          }
          .button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 528px) {
  .about-wrapper {
    .contact {
      .container-main {
        .text {
          h2 {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
