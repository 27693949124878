body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-bar {
  position: fixed;
  right: 0;
  top: 90%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-15%);
  i {
    display: flex;
    height: 30px;
    width: 30px;
  }
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 12px;
  transition: all 0.3s ease;
  color: white;
  font-size: 30px;
  text-decoration: none;
  background: #25d366;
  border-radius: 50%;
}

.icon-bar a:hover {
  background-color: #128974;
}

.size-guide {
  .button {
    color: white;
    position: fixed;
    z-index: 99999;
    top: 50%;
    right: -35px;
    transform: rotateZ(90deg);
    button {
      display: block;
      border: 1px white solid;
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.211);
      color: black;
      border-radius: 8px;
      padding: 10px 26px;
      margin: 0;
      text-decoration: none;
      width: -moz-fit-content;
      width: fit-content;
      background-image: linear-gradient(56.47deg, #e96701 0%, #faa805 100%);
      color: white;
      border: none;
     
    }
  }
  .modal {
    .modal-body {
      width: 100%;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
