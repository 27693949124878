.resourcecenter {
  .resource-hero-img-section {
    background-image: url("../../assets/homehero.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    .resource-main-hero-content-section {
      padding: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      .resource-content {
        padding-top: 3rem;
        padding-top: 80px;
        font-size: 4rem;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  .search {
    margin-bottom: 1rem;
    margin-top: -5rem;
    .search-container {
      width: 85%;
      margin: auto;
      background-color: #f5f5f5;
      border-radius: 15px;
      padding: 30px 30px;
      box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      margin-top: 20px;
      .forminput {
        display: flex;
        background-color: white;
        align-items: center;
        width: 70%;
        margin-left: auto;
        border-radius: 10px;
        color: #c6a3a3;
        input {
          padding: 10px;
          width: 100%;
          outline: none;
          border: none;
          border-radius: 10px;
          color: #c6a3a3;
        }
        .icon {
          padding: 10px;
          width: fit-content;
        }
      }
    }
    .search-results {
      // display: flex;
      // flex-direction: row;
      width: 90%;
      margin: auto;
      padding: 0px;
      .no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          padding-top: 30px;
          font-size: 22px;
        }
      }
      .blog-result {
        .column-gapping {
          padding: 20px;
          border-radius: 20px;
          .blog-card {
            box-shadow: 0px 0px 20px 0px rgb(107, 143, 178, 0.25);
            border-radius: 20px;
            padding: 5px;
            background-color: white;
            .img-outer {
              align-items: center;
              justify-content: center;
              display: flex;
              border-radius: 20px 20px 10px 10px;
              height: 250px;
              overflow: hidden;
              img {
                // background-color: red;
                height: 100%;
              }
            }
            .info {
              padding: 20px 15px 10px 10px;
              h5 {
                color: #666666;
              }
              .readmore {
                width: 150px;
                padding: 0px;
                border-radius: 10px;
                background-color: #666666;
                a {
                  text-align: center;
                  padding: 15px;
                  width: 100%;
                  display: block;
                  color: white;
                  text-decoration: none;
                  line-height: 1;
                  margin: 0;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
    .submit {
      display: flex;
      height: 100%;
      align-items: center;
      button {
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        color: white;
        background-image: linear-gradient(30deg, #0f2699, #00aaff);
      }
    }
  }
  .latest-posts {
    .container-main {
      padding: 20px 30px;
      box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      background-color: #f5f5f5;
      width: 85%;
      margin: auto;
      border-radius: 15px;
      .heading {
        position: relative;
        padding: 10px;
        h5 {
          width: fit-content;
          margin: auto;
          position: relative;
          font-size: 45px;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );
            
          &:before {
            content: "";
            position: absolute;
            width: 130px;
            height: 5px;
            background-color: orange;
            top: 100%;
            border-radius: 20px;
          }

          // &:after {
          //   content: "";
          //   position: absolute;
          //   width: 50px;
          //   height: 5px;
          //   background-color: orange;
          //   top: 50%;
          //   right: -50px;
          //   border-radius: 20px;
          // }
          // &:before {
          //   content: "";
          //   position: absolute;
          //   width: 50px;
          //   height: 5px;
          //   background-color: orange;
          //   top: 50%;
          //   left: -50px;
          //   border-radius: 20px;
          // }
        }
      }
      .posts {
        width: 100%;
        margin: auto;
        padding: 20px;
        .owl-nav {
          position: absolute;
          width: 100%;
          left: 0;
          top: 50%;
          transform: translatey(-150%);
          display: flex;
          justify-content: space-between;
          button {
            transform: scale(2.5);
            &:hover {
              background-color: transparent;
              color: #000000;
              transform: translate(0, 0) scale(3);
            }
          }
        }
        .column-gapping {
          padding: 20px;
          border-radius: 20px;
          .blog-card {
            box-shadow: 0px 0px 20px 0px rgb(107, 143, 178, 0.25);
            border-radius: 20px;
            padding: 5px;
            background-color: white;
            .img-outer {
              border-radius: 20px 20px 10px 10px;
              height: 250px;
              overflow: hidden;
              img {
                // background-color: red;
                height: 100%;
              }
            }
            .info {
              padding: 20px 15px 10px 10px;
              h5 {
                color: #666666;
              }
              .readmore {
                width: 150px;
                padding: 0px;
                border-radius: 10px;
                background-color: #666666;
                a {
                  text-align: center;
                  padding: 15px;
                  width: 100%;
                  display: block;
                  color: white;
                  text-decoration: none;
                  line-height: 1;
                  margin: 0;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
  .all-posts {
    // padding: 20px;
    // margin-top: 35px;
    .container-main {
      padding: 40px 0px;
      width: 95%;

      margin: auto;
      .heading {
        position: relative;
        h5 {
          width: fit-content;
          margin: auto;
          position: relative;
          font-size: 45px;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );

         
          &:before {
            content: "";
            position: absolute;
            width: 60px;
            height: 5px;
            background-color: orange;
            top: 100%;
            border-radius: 20px;
          }
        }
      }
      .posts {
        width: 90%;
        margin: auto;
        padding: 20px;
        .column-gapping {
          padding: 20px;
          border-radius: 20px;
          .blog-card {
            box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
            border-radius: 15px;
            padding: 5px;
            background-color: white;
            .img-outer {
              border-radius: 10px;
              height: 250px;
              justify-content: center;
              display: flex;
              overflow: hidden;
              img {
                // background-color: red;
                height: 100%;
                width: 100%;
              }
            }
            .info {
              padding: 20px 15px 10px 10px;
              h5 {
                color: #666666;
              }
              .readmore {
                width: 150px;
                padding: 0px;
                border-radius: 10px;
                background-color: #666666;
                a {
                  text-align: center;
                  padding: 15px;
                  width: 100%;
                  display: block;
                  color: white;
                  text-decoration: none;
                  line-height: 1;
                  margin: 0;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .resourcecenter {
    .resource-hero-img-section {
      .resource-main-hero-content-section {
        .resource-content {
          padding-bottom: 3rem;
        }
      }
    }
    .search {
      padding: 0;
      .search-container {
        padding: 15px 0px;
        .search-text {
          text-align: center;
          h3{
            font-size: 1.2rem;
          }
        }
        .forminput {
          margin: auto;
          input{
            font-size: 12px;
          }
        }
      }
      .search-results {
        padding: 0;
      }
    }
    .latest-posts {
      .container-main {
        padding: 20px 10px;
        width: 100%;
        .heading {
          h5 {
            padding: 0;
            font-size: 30px;
          }
        }
        .posts {
          padding: 20px 5px;
          width: 100%;
          .column-gapping {
            .blog-card {
              .img-outer {
                height: 200px;
                img {
                  
                  border-radius: 15px;
                }
              }
              .info {
                h5 {
                  font-size: 18px;
                }
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .all-posts {
      padding: 0;
      margin-top: 0;
      .container-main {
        padding: 10px 30px;
        width: 100%;
        .heading {
          h5 {
            padding: 0;
            font-size: 30px;
          }
        }
        .posts {
          width: 100%;
          padding: 0;
          .column-gapping {
            .blog-card {
              .img-outer {
                height: 175px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                }
              }
              .info {
                padding: 10px;
                h5 {
                  font-size: 18px;
                }
                p {
                  font-size: 14px;
                  margin-bottom: 7px;
                }
                .readmore {
                  a {
                    padding: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
