.singleResource {

  .singleResource-hero-img-section {
    background-image: url("../../assets/homehero.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    .singleResource-main-hero-content-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      width: 70%;
      max-height: 400px;
      .singleResource-image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          margin: 0;
          margin-top: 7rem;
          border-radius: 15px;
          max-width: 100%;
        }
      }
    }
  }
  .singleResource-content {
    width: 85%;
    margin: auto;
    margin-top: 10rem;
background-color: #f5f5f5;
box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.1);
padding: 20px;
border-radius: 15px;
    .heading {
      h1 {
        color: #676767;
        padding-bottom: 1.5rem;
      }
    }
    .keywordsAndShare {
      padding-bottom: 1.5rem;
      .keyword-outer {
        display: flex;
        flex-direction: row;
        .keyword-inner {
          padding-right: 10px;

          p {
            color: black;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 7px;
            box-shadow: 10px 10px 80px 2px rgba(128, 128, 128, 0.496);
          }
        }
      }
    }
  }

  .latest-posts {
    margin-bottom: 40px;
    margin-top: 60px;
    .container-main {
      padding: 40px 0;
      box-shadow: 20px 20px 40px 0px rgb(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      width: 85%;
      margin: auto;
      border-radius: 30px;
      .heading {
        position: relative;
        h5 {
          width: fit-content;
          margin: auto;
          position: relative;
          padding: 20px;
          font-size: 45px;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );

          // &:after {
          //   content: "";
          //   position: absolute;
          //   width: 50px;
          //   height: 5px;
          //   background-color: orange;
          //   top: 50%;
          //   right: -50px;
          //   border-radius: 20px;
          // }
          // &:before {
          //   content: "";
          //   position: absolute;
          //   width: 50px;
          //   height: 5px;
          //   background-color: orange;
          //   top: 50%;
          //   left: -50px;
          //   border-radius: 20px;
          // }
        }
      }
      .posts {
        width: 90%;
        margin: auto;
        padding: 20px;
        .column-gapping {
          padding: 20px;
          border-radius: 20px;
          .blog-card {
            box-shadow: 0px 0px 20px 0px rgb(107, 143, 178, 0.25);
            border-radius: 20px;
            padding: 5px;
            background-color: white;
            .img {
              border-radius: 20px 20px 10px 10px;
              height: 250px;
              overflow: hidden;
              img {
                // background-color: red;
                height: 100%;
              }
            }
            .info {
              padding: 20px 15px 10px 10px;
              h5 {
                color: #666666;
              }
              .readmore {
                width: 150px;
                padding: 0px;
                border-radius: 10px;
                background-color: #666666;
                a {
                  text-align: center;
                  padding: 15px;
                  width: 100%;
                  display: block;
                  color: white;
                  text-decoration: none;
                  line-height: 1;
                  margin: 0;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .singleResource {
    .singleResource-hero-img-section {
      .singleResource-main-hero-content-section {
        max-height: 300px;
        .singleResource-image {
          
          display: flex;
          align-items: center;
          img {
            height: 70%;
            
            max-width: 85%;
            margin: auto;
            margin-top: 6rem;
          }
        }
      }
    }
    .latest-posts {
      margin-top: 2rem;
      margin-bottom: 2rem;
      .container-main {
        padding: 20px 10px;
        border-radius: 15px;
        width: 85%;
        .heading {
          h5 {
            padding: 0;
            font-size: 30px;
          }
        }
        .posts {
          padding: 20px 5px;
          width: 100%;
          .column-gapping {
            padding: 10px;

            .blog-card {
              .img {
                height: 200px;
                img {
                  height: 100%;
                }
              }
              .info {
                h5 {
                  font-size: 18px;
                }
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .singleResource-content{
      padding-top: 3rem;
      width: 85%;
      margin: auto;
      margin-top: 3rem;
      margin-bottom: 1rem;
      .heading{
        text-align: center;
        h1{
          font-size: 25px;
          padding-bottom: 0;
        }
      }
      .keywordsAndShare{
        display: flex;
        justify-content: center;
        padding-bottom: 0;
        p{
          font-size: 16px;
        }
      }
      .description{
        text-align: center;
        p{
          font-size: 14px;
        }
      }
    }
  }
}
