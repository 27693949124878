
.footer {
  max-width: 100%;
  background-image: linear-gradient(
    -40deg,
    #151515a2 10%,
    #151515b6 58%,
    #e3e3e37a 252%
  );
  background-color: black;
  padding: 5% 2%;
  color: white;
  padding-bottom: 1%;
  .row {
    .copyright {
      .logo {
        img {
          width: 45%;
          margin-bottom: 20px;
        }
      }
      .details {
        padding-bottom: 4px;
        padding-left: 14px;
        p {
          font-size: 13px;
          line-height: 1rem;
          text-align: justify;
          a {
            text-decoration: none;
            color: white;
          }
        }
      }
      .social {
        display: flex;
        padding-left: 14px;
        a {
          padding: 2px;
          height: 27px;
          width: 27px;
          background-color: white;
          border-radius: 5px;
          font-size: 13px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin: 5px;
          color: #2b2525ce;
        }
      }
    }
    .footer-menu {
      padding-right: 0;
      .background-div {
        padding: 15px;
        height: 100%;
        border-radius: 20px;
        background-image: linear-gradient(
          20deg,
          #5e646c13 3%,
          #9a828210 50%,
          #a6b0ba1b
        );
        .category-item{
          &:hover{
            cursor: pointer;
          }
        }
      }
      .company {
        position: relative;

        margin-bottom: 10px;
        h5 {
          position: relative;
          margin-bottom: 20px;
        }
        h5::after {
          content: "";
          height: 3px;
          width: 35px;
          background-color: white;
          position: absolute;
          bottom: -5px;
          left: 0px;
        }
        ul {
          margin-left: 0;
          padding-left: 0;
          li {
            padding-bottom: 4px;
            list-style: none;
            a {
              text-decoration: none;
              color: white;
              font-size: 13px;
            }
          }
        }
      }
      .shop {
        margin-bottom: 10px;
        padding-right: 0;
        h5 {
          position: relative;
          margin-bottom: 20px;
        }
        h5::after {
          content: "";
          height: 3px;
          width: 30px;
          background-color: white;
          position: absolute;
          bottom: -5px;
          left: 0px;
        }
        ul {
          margin-left: 0;
          padding-left: 0;
          li {
            padding-bottom: 4px;
            list-style: none;
            padding-left: 0;
            a {
              text-decoration: none;
              color: white;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .bottomcopyright {
    margin: auto;
    height: 4rem;
    display: flex;
    align-items: end;
    justify-content: space-between;
    p {
      color: white;
      font-size: 14px;
      margin-bottom: 0 !important;
      padding-left: 13px;
      a {
        text-decoration: none;
        font-weight: bold;
        color: white;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    .row {
      row-gap: 10px;
      --bs-gutter-x: 0rem;
      max-width: 100%;
      .copyright {
        .logo {
          img {
            margin-top: 62px;
          }
        }
      }
      .footer-menu {
        .shop {
          padding-right: calc(var(--bs-gutter-x) * 0.5);
          padding-bottom: 28px;
          width: 92%;
          margin: auto;
        }
        .company {
          width: 92%;
          margin: auto;
        }
      }
    }
    .bottomcopyright {
      p {
        .powered {
          padding-left: 0px;
        }
      }
    }
  }
}


@media (max-width: 528px){
  .footer{
    .bottomcopyright{
      flex-direction: column;
      align-items: start;
    }
  }
}
