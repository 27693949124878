.homeMain {
  background-color: #fff;
  .homeHero {
    padding-top: 8rem;
    width: 100%;
    padding-bottom: 2rem;
    .homeHeroMain {
      border: 4px solid orange;
      background-image: -webkit-linear-gradient(
        45deg,
        #0f2699 19.79%,
        #00aaff 84.88%
      );
      display: flex;
      background-repeat: no-repeat;
      background-size: cover;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 93%;
      margin: auto;
      padding: 1% 4%;
      border-radius: 25px;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
      .right-carousel {
        height: auto;
        .owl-stage {
          display: flex;
          align-items: center;
          justify-content: center;
          .owl-item:nth-child(even) {
            .hero-carousel-item {
              .carousel-side {
                flex-direction: row;
              }
            }
          }
          .owl-item:nth-child(odd) {
            .hero-carousel-item {
              .carousel-side {
                flex-direction: row-reverse;
                .text-left h2 {
                  text-align: right;
                }
                .readmore {
                  a {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
        .hero-carousel-item {
          max-width: 100% !important;
          .text-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2 {
              cursor: default;
              font-size: 4.9rem;
              font-weight: bolder;
              -webkit-background-clip: text;
              background-clip: text;
              color: white;
              padding-bottom: 14px;
            }
            .readmore {
              overflow: visible;
              a {
                display: block;
                box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.211);
                color: black;
                border-radius: 8px;
                padding: 10px 26px;
                margin: 0;
                text-decoration: none;
                width: -moz-fit-content;
                width: fit-content;
                background-image: linear-gradient(
                  56.47deg,
                  #e96701 0%,
                  #faa805 100%
                );
                color: white;
                border: none;
              }
            }
          }
          .image-card-hero {
            min-width: 100%;
            flex-direction: column;
            img {
              width: auto;
              max-width: 81%;
              // max-height: 80%;
              // width:  450px !important;
            }
          }
        }
        .owl-dot {
          display: none;
        }
      }
    }
  }

  .usp {
    width: 90%;
    margin: auto;
    background-color: white;
    border-radius: 20px;
    padding: 2%;
    // margin-top: -5rem;
    .key-points {
      .key-card {
        height: 270px;
        min-height: 250px;
        margin: auto;
        padding: 17px;
        .gapping {
          border: 1px solid #e7cbcb95;
          box-shadow: 0px 0px 15px 1px rgba(128, 128, 128, 0.156);
          height: 100%;
          border-radius: 10px;
          padding: 20px 25px;
          text-align: center;
          .card-image {
            width: 100%;
            padding-bottom: 10px;
            img {
              max-width: 60px;
              margin: auto;
            }
          }
          .card-text {
            padding: 10px;
            h6 {
              font-size: 18px;
              -webkit-text-fill-color: #0000;
              -webkit-background-clip: text;
              background-clip: text;
              background-image: -webkit-linear-gradient(
                45deg,
                #0f2699 19.79%,
                #00aaff 84.88%
              );
              position: relative;
              &:after {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                width: 35%;
                height: 2px;
                background-color: orange;
              }
            }
            p {
              font-size: 14px;
              padding-top: 15px;
              text-align: center;
              color: #6b3f87;
            }
          }
          &:hover {
            box-shadow: 0px 0px 15px 1px rgba(128, 128, 128, 0.875);
            transform: scale(1.01);
          }
        }
      }

      .key-card-1 {
        .gapping {
          cursor: default;

          background-color: #deebec0f;
        }
      }
      .key-card-2 {
        .gapping {
          cursor: default;

          background-color: #8579e30f;
        }
      }
      .key-card-3 {
        .gapping {
          cursor: default;

          background-color: #e9d6c50f;
        }
      }
      .key-card-4 {
        .gapping {
          cursor: default;
          background-color: #bcacd71b;
        }
      }
    }
  }

  .our-clients {
    .our-clients-sub {
      padding-top: 3%;
      padding-bottom: 3%;
      width: 85%;
      margin: auto;
      .heading {
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 45px;
          padding-bottom: 10px;
        }
        h6 {
          font-size: 20px;
        }
      }
      .logo-carousel {
        // padding: 20px;
        // height: 120px;
        // width: fit-content;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          img {
            max-height: 100%;
            width: fit-content;
            max-width: 69%;
          }
        }
        .scrollc {
          .slick-slider {
            .slick-list {
              .slick-track {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .our-fabric-partner {
    .our-fabric-sub {
      width: 85%;
      margin: auto;
      padding-top: 3%;
      padding-bottom: 3%;
      .heading {
        padding-right: 20px;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 45px;
          padding-bottom: 10px;
        }
        h6 {
          font-size: 20px;
        }
      }
      .logo-carousel {
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          img {
            max-height: 100%;
            width: fit-content;
            max-width: 69%;
          }
        }
        .scrollc {
          .slick-slider {
            .slick-list {
              .slick-track {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .call-back {
    background-image: linear-gradient(
      72.03deg,
      #000000 25.57%,
      #626ea6 45.46%,
      #2843cb 58.21%,
      #00aaff 75.51%
    );
    padding: 40px 0px;
    .container {
      width: 85%;
      .heading {
        align-items: center;
        // justify-content: center;
        display: flex;
        h4 {
          // padding:
          color: white;
          font-size: 25px;
          text-transform: uppercase;
        }
      }
      .callback-form {
        .form {
          width: 70%;
          margin-left: auto;
          display: flex;
          justify-content: space-between;
          input[type="tel"] {
            padding: 10px;
            border-radius: 5px;
            border: none;
            &:focus {
              outline: none;
            }
          }
          button {
            color: white;
            border-radius: 50px;
            outline: none;
            border: none;
            padding: 10px 15px;
            transition: 10s infinite;
            background-image: linear-gradient(
              56.47deg,
              #e96701 0%,
              #faa805 100%
            );
            &:hover {
              transform: scale(1.02);
              // background-image: linear-gradient(56.47deg, #FAA805  0%, #E96701 70%);
              box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.27);
            }
          }
        }
      }
    }
  }

  .homeGifting {
    padding: 2%;
    .homeGiftingSub {
      box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.496);

      padding-top: 2rem;
      width: 90%;
      margin: auto;
      background-color: #f0f0f0;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .giftingText {
        position: relative;
        h2 {
          cursor: default;
          font-size: 2.5rem;
          font-family: Oswald;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );
        }
      }
      .giftingCard {
        padding: 2%;
        width: 90%;
        margin: auto;
        position: relative;
        .owl-nav {
          position: absolute;
          width: 100%;
          left: 0;
          top: 50%;
          transform: translatey(-150%);
          display: flex;
          justify-content: space-between;
          button {
            transform: scale(2.5);
            &:hover {
              background-color: transparent;
              color: #000000;
              transform: translate(0, 0) scale(3);
            }
          }
        }
        .owl-dots {
          display: none;
        }
      }
    }
  }

  .WhyUs {
    background-color: #f0f0f0;
    width: 100%;
    padding: 2%;
    .whyUsText {
      width: fit-content;
      display: flex;
      align-items: center;
      margin: auto;
      justify-content: center;
      position: relative;
      h2 {
        font-size: 45px;
        // color: #4c4c4c;
        font-family: Oswald;
        -webkit-text-fill-color: #0000;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: -webkit-linear-gradient(
          45deg,
          #0f2699 19.79%,
          #00aaff 84.88%
        );
      }
    }
    .whyUsContainer {
      width: 75%;
      margin: auto;
      .whyUs-section1 {
        padding-top: 10%;
        height: 90%;
        .col-md-4 {
          .whyus-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 35px;
            border-radius: 20px;
            height: 100%;
            backdrop-filter: blur(250px);
          }
          &:nth-child(1) .whyus-card {
            background-image: radial-gradient(
                circle at 29% 107%,
                rgba(255, 178, 26, 0.2) 9%,
                transparent 38%
              ),
              radial-gradient(
                circle at 68% 22%,
                rgba(255, 178, 26, 0.2) 9%,
                transparent 38%
              ),
              linear-gradient(white, white);
            border: 3px solid #ffb21a;
            row-gap: 25px;
            .icon-border {
              width: 90px;
              height: 90px;
              margin-top: -85px;
              padding: 5px;
              border: 4px solid #ffb21a;
              border-radius: 100%;
              .icon {
                padding: 3px;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                color: #ffb21a;
                img {
                  max-width: 43px;
                  max-height: 43px;
                }
              }
            }
            .heading {
              color: gray;
              h4 {
                text-align: center;

                font-size: 21px;
              }
              span {
                color: #ffb21a;
                border-bottom: 2px solid #ffb21a;
              }
            }
            .description {
              p {
                text-align: justify;
              }
            }
          }
          &:nth-child(2) .whyus-card {
            background-image: radial-gradient(
                circle at 29% 107%,
                rgba(98, 175, 121, 0.2) 9%,
                transparent 38%
              ),
              radial-gradient(
                circle at 68% 22%,
                rgba(98, 175, 121, 0.2) 9%,
                transparent 38%
              ),
              linear-gradient(white, white);
            border: 3px solid #5bb671;
            row-gap: 25px;
            .icon-border {
              width: 90px;
              height: 90px;
              margin-top: -85px;
              padding: 5px;
              border: 4px solid #5bb671;
              border-radius: 100%;
              .icon {
                padding: 3px;
                width: 100%;

                height: 100%;
                border-radius: 100px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                color: #5bb671;
                img {
                  max-width: 40px;
                  max-height: 40px;
                }
              }
            }
            .heading {
              color: gray;
              h4 {
                text-align: center;

                font-size: 21px;
              }
              span {
                color: #5bb671;
                border-bottom: 2px solid #5bb671;
              }
            }
            .description {
              p {
                text-align: justify;
              }
            }
          }
          &:nth-child(3) .whyus-card {
            background-image: radial-gradient(
                circle at 29% 107%,
                rgba(28, 140, 151, 0.2) 9%,
                transparent 38%
              ),
              radial-gradient(
                circle at 68% 22%,
                rgba(28, 140, 151, 0.2) 9%,
                transparent 38%
              ),
              linear-gradient(white, white);
            border: 3px solid #198cff;
            row-gap: 25px;
            .icon-border {
              width: 90px;
              height: 90px;
              margin-top: -85px;
              padding: 5px;
              border: 4px solid #198cff;
              border-radius: 100%;
              .icon {
                padding: 3px;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                color: #198cff;
                img {
                  max-width: 40px;
                  max-height: 40px;
                }
              }
            }
            .heading {
              color: gray;
              h4 {
                text-align: center;
                font-size: 21px;
              }
              span {
                color: #198cff;
                border-bottom: 2px solid #198cff;
              }
            }
            .description {
              p {
                text-align: justify;
              }
            }
          }
        }
      }
    }
  }

  .testimonial {
    .container-main {
      width: 90%;
      margin: auto;
      background-color: white;
      border-radius: 20px;
      padding-top: 2rem;
      .heading {
        width: fit-content;
        display: flex;
        align-items: center;
        margin: auto;
        justify-content: center;
        position: relative;
        h2 {
          font-size: 45px;
          font-family: Oswald;
          -webkit-text-fill-color: #0000;
          padding: 10px;
          width: fit-content;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );
        }
      }

      .key-points {
        padding: 20px;
        .key-card {
          height: 280px;
          min-height: 250px;
          width: 100%;
          margin: auto;
          padding: 20px;
          .gapping {
            height: 100%;
            width: 90%;
            box-shadow: 0px 0px 10px 1px rgba(128, 128, 128, 0.196);
            margin: auto;
            border-radius: 15px;
            padding: 20px;
            text-align: center;
            column-gap: 15px;

            .card-upper-section {
              padding: 0;
              .card-upper-inner {
                max-height: 50px;
                max-width: 50px;
              }
            }
            .card-mid-section {
              padding: 0;
              .card-mid-inner {
                text-align: left;
                p {
                  font-size: 14px;
                  text-align: justify;
                }
              }
            }
            .card-bottom-section {
              padding: 0;
              .card-bottom-inner {
                display: flex;
                // justify-content: space-around;
                align-items: center;
                flex-direction: row;
                gap: 15px;
                .card-image {
                  img {
                    width: 45px;
                    height: 45px;
                    border-radius: 40px;
                  }
                }
                .card-name {
                  text-align: start;
                  .name-upper {
                    p {
                      -webkit-text-fill-color: #0000;
                      -webkit-background-clip: text;
                      background-clip: text;
                      background-image: -webkit-linear-gradient(
                        45deg,
                        #0f2699 19.79%,
                        #00aaff 84.88%
                      );
                      font-weight: bold;
                      padding-bottom: 0;
                      margin-bottom: 0;
                      font-size: 16px;
                    }
                  }
                  .name-down {
                    p {
                      font-size: 14px;
                      padding: 0;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            &:hover {
              transform: translate(0, 0) scale(1.03);
              box-shadow: 0px 0px 10px 1px rgba(128, 128, 128, 0.697);
            }
          }
        }
        // .owl-item:nth-child(3n) .gapping {
        //   background-color: #b3ff1a0f;
        // }
        // .owl-item:nth-child(3n + 1) .gapping {
        //   background-color: #1a8cff0f;
        // }
        // .owl-item:nth-child(3n + 2) .gapping {
        //   background-color: #5ab6710f;
        // }
        .owl-nav {
          position: absolute;
          width: 102%;
          left: -1%;
          top: 50%;
          transform: translatey(-150%);
          display: flex;
          justify-content: space-between;
          button {
            transform: scale(2.5);
            &:hover {
              background-color: transparent;
              transform: translate(0, 0) scale(3);
              color: black;
            }
          }
        }
      }
    }
  }
  .contact {
    padding-top: 2rem;
    position: relative;
    .container-main {
      width: 75%;
      // position: absolute;
      margin: auto;
      margin-bottom: -4rem;
      box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      padding: 4% 4%;
      border-radius: 20px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        color: black;
        font-weight: bold;
        position: relative;
        h1 {
          font-family: inherit;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );
          font-size: 45px;
          font-weight: bold;
          span {
            color: red;
          }
        }
        p {
          width: 83%;
          font-weight: 100;
        }
      }
      .button {
        .learn-more {
          padding-top: 10px;
          font-size: 19px;
          text-align: center;
        }
      }
      a {
        display: block;
        background-image: linear-gradient(
          -79deg,
          #ffaa00 0.36%,
          #e57301 117.12%
        );
        color: white;
        border-radius: 10px;
        padding: 15px;
        margin: 0;
        text-decoration: none;
        p {
          margin: 0;
          text-align: justify;
        }
      }
    }
  }
  .owl-dots {
    display: none;
  }
  .footer {
    padding-top: 6rem !important;
  }
}

@keyframes herounipridetext {
  0% {
    margin-top: 0;
    width: 0%;
  }
  2.5% {
    width: 100%;
  }
  14% {
    width: 100%;
  }
  16.49% {
    width: 0%;
    margin-top: 0%;
  }
  16.5% {
    margin-top: -300px;
    width: 0%;
  }
  19% {
    margin-top: -300px;
    width: 100%;
  }
  30.5% {
    width: 100%;
  }
  32.99% {
    margin-top: -300px;
    width: 0%;
  }
  33% {
    margin-top: -600px;
    width: 0%;
  }
  35.5% {
    width: 100%;
  }
  47% {
    width: 100%;
  }
  49.49% {
    margin-top: -600px;
    width: 0%;
  }
  49.5% {
    width: 0%;
    margin-top: -900px;
  }
  52% {
    width: 100%;
  }
  63.5% {
    width: 100%;
  }
  65.99% {
    margin-top: -900px;
    width: 0%;
  }
  66% {
    margin-top: -1200px;
    width: 0%;
  }
  68.5% {
    width: 100%;
  }
  80% {
    width: 100%;
  }
  82.49% {
    margin-top: -1200px;
    width: 0%;
  }
  82.5% {
    margin-top: -1500px;
    width: 0%;
  }
  85% {
    width: 100%;
  }
  96.5% {
  }
  98.99% {
    width: 100%;
  }
  99% {
    margin-top: -1500px;
    width: 100%;
  }
  99.5% {
    margin-top: -1500px;
    width: 0%;
  }
  100% {
    margin-top: -0px;
    width: 0%;
  }
}

@media (max-width: 767px) {
  .homeMain {
    .homeHero {
      padding-top: 6rem;
      padding-bottom: 0;
      .homeHeroMain {
        border: 3px solid orange;
        padding-top: 2%;
        width: 85%;
        .right-carousel {
          padding: 0;
          .hero-carousel-item {
            .text-left {
              h2 {
                text-align: center !important;
                font-size: 2rem;
                padding-bottom: 0;
                text-align: center;
              }
              .readmore {
                align-items: center;
                justify-content: center;
                display: flex;
                a {
                  font-size: 14px;
                  padding: 2px 10px;
                  margin: auto;
                }
              }
            }
            .image-card-hero {
              padding: 0;
              img {
                padding: 0;
                padding-top: 0.8rem;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    .usp {
      width: 85%;
      .key-points {
        padding: 0;
        padding-bottom: 20px;
        padding-top: 20px;

        .key-card {
          min-height: fit-content;
          height: fit-content;
          .gapping {
            padding: 9px;
            .card-image {
              padding: 0;
              img {
                max-width: 50px;
              }
            }
            .card-text {
              h6 {
                font-size: 16px;
              }
              p {
                font-size: 12px;
                padding-bottom: 0;
                margin-bottom: 0;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .homeGifting {
      .homeGiftingSub {
        padding-bottom: 2rem;
        .giftingText {
          text-align: center;
          h2 {
            font-size: 2.2rem;
          }
        }
        .giftText {
          .giftName {
            h5 {
              font-size: 30px;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
      .giftingCard {
        .giftImage {
          img {
            padding: 20px;
          }
        }
        .giftText {
          .submit-btn {
            padding-top: 7px;
            button {
              font-size: 16px;
              width: fit-content;
              padding: 4px 17px;
            }
          }
        }
      }
    }
    .our-clients {
      .our-clients-sub {
        padding-top: 10%;
        .heading {
          h2 {
            padding-bottom: 0;
            font-size: 23px;
          }
          h6 {
            font-size: 15px;
          }
        }
        .logo-carousel {
          .owl-carousel {
            .owl-item {
              padding: 0;
            }
          }
        }
      }
    }
    .our-fabric-partner {
      .our-fabric-sub {
        padding-bottom: 10%;
        .heading {
          text-align: end;
          h2 {
            font-size: 23px;
            padding-bottom: 0;
          }
          h6 {
            font-size: 15px;
          }
        }
        .logo-carousel {
          .owl-carousel {
            .owl-item {
              padding: 0;
            }
          }
        }
      }
    }
    .WhyUs {
      padding-bottom: 2rem;
      .whyUsContainer {
        .whyUs-section1 {
          row-gap: 55px;
          padding-top: 20%;
        }
        .whyUs-section1 {
          .col-md-4:nth-child(1),
          .col-md-4:nth-child(2),
          .col-md-4:nth-child(3) {
            .whyus-card {
              row-gap: 10px;
              padding: 20px;
              .icon-border {
                width: 70px;
                height: 70px;
                margin-top: -60px;
                .icon {
                  font-size: 30px;
                }
              }
              .heading {
                h4 {
                  font-size: 20px;
                }
              }
              .description {
                p {
                  font-size: 13px;
                  text-align: justify;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .call-back {
      padding: 25px 0px;
      .container {
        width: 95%;
        .callback-form {
          .form {
            margin: auto;
            width: 100%;
            input {
              font-size: 12px;
            }
            button {
              font-size: 12px;
              padding: 10px 15px;
              border-radius: 15px;
            }
          }
        }
        .heading {
          h4 {
            font-size: 18px;
          }
        }
      }
    }
    .contact {
      .container-main {
        .text {
          padding: 0;
          h2 {
            font-size: 18px;
          }
          p {
            text-align: justify;
            width: 95%;
            font-size: 12px;
          }
        }
        .button {
          padding: 0;

          a {
            font-size: 16px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 20px !important;
            font-size: 14px;
          }
        }
      }
    }
    .testimonial {
      .container-main {
        padding-top: 0;
        width: 95%;
        .key-points {
          padding: 0;
          .key-card {
            .gapping {
              box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.496);

              .card-upper-section {
                padding: 0;
                .card-upper-inner {
                  max-height: 50px;
                  max-width: 50px;
                }
              }
              .card-mid-section {
                padding: 0;
                .card-mid-inner {
                  text-align: start;
                  p {
                    margin-bottom: 0;
                    font-size: 14px;
                    text-align: justify;
                  }
                }
              }
              .card-bottom-section {
                .card-bottom-inner {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  gap: 10px;
                  .card-image {
                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 50px;
                    }
                  }
                  .card-name {
                    text-align: left;
                    .name-upper {
                      p {
                        -webkit-text-fill-color: #0000;
                        -webkit-background-clip: text;
                        background-clip: text;
                        background-image: -webkit-linear-gradient(
                          45deg,
                          #0f2699 19.79%,
                          #00aaff 84.88%
                        );
                        font-weight: bold;
                        padding-bottom: 0;
                        margin-bottom: 0;
                      }
                      .name-down {
                        text-align: start;
                        p {
                          float: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
