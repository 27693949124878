.size-guide{
    .size-category{
        .card-btn{
            padding: 2rem;
            margin: 1rem auto;
            width: 90%;
            display: block;
        }
    }
    .modal-school-uniform{
        .modal-dialog{
            max-width: 90%;
            .modal-body{
                .tabs{
                    .nav-tabs{
                        margin: auto;
                    }
                    .tab-content{   
                        .tables{
                            .table-item{
                                margin-top: 1rem;
                                margin-bottom: 2rem;
                                overflow-y: scroll;
                                overflow-x: auto;
                                &::-webkit-scrollbar{
                                    display: none;
                                }
                                table{
                                    // width: 80%;
                                    margin: auto;
                                    th{
                                        padding: 5px 5px 5px 10px;
                                    }
                                    td{
                                        padding: 5px 5px 5px 10px;
                                    }
                                }
                            }
                        }
                        .table-images{
                            max-width: 95% ;
                            .row-img{
                                text-align: center;
                                img{
                                    // max-width: 70%;
                                    max-width: fit-content;
                                    max-height: 200px;
                                    margin-top: 2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}

.image-outer{
height: 500px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
img{
    height: 100%;
width: auto!important;}
}


@media(max-width:767px){
    .image-outer{
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img{
            height: 100%;
            width: auto!important;
        }
    }
}