.login{
    min-height: 100vh;
    .login-outer {
      
        padding-top: 10%;
        padding-bottom: 10%;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-inner {
          
          width: 70%;
          margin: auto;
          .login-form {
            max-width: 35rem;
            margin: auto;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 8%;
            border-radius: 10px;
            box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
            .login-text {
              h1 {
                padding-bottom: 2rem;
              }
            }
            .inputs {
              padding: 1%;
              width: 90%;
              .login-name {
                width: 100%;
                padding-bottom: 10%;
      
                input {
                  width: 100%;
                  text-decoration: none;
                  background-color: transparent;
                  border: none;
                  border-bottom: 1px solid black;
                }
                input:focus {
                  outline: 0px;
                  color: #0099e5;
                }
              }
              .login-password {
                padding-bottom: 10%;
                input {
                  width: 100%;
                  border-radius: 0;
                  outline: 0;
                  text-decoration: none;
                  background-color: transparent;
                  border: none;
                  border-bottom: 1px solid black;
                }
                input:focus {
                  outline: 0px;
                  color: #0099e5;
                }
              }
            }
            .forget-outer {
              .forget-text {
                display: flex;
                align-items: flex-start;
                a {
                  text-decoration: none;
                }
              }
            }
            .login-button-outer {
              width: 90%;
              padding-top: 8%;
              cursor: pointer;
              .login-button {
                background-image: linear-gradient(
                  -79deg,
                  #351f99 0.36%,
                  #0099e5 117.12%
                );
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1%;
                border-radius: 1rem;
      
                h4 {
                  color: white;
                }
              }
            }
          }
        }
      }
      
}

@media (max-width: 768px) {
  .login{
    .login-outer{
      padding-top: 20%;
      .login-inner{
        width: 90%;
      }
    }
  }
  
}