.OuterDOPri {
  padding-top: 8rem;
  height: 100%;
  width: 100vw;

  .justDo {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 1rem;
    align-items: start;
    flex-direction: column;

    list-style: none;
    counter-reset: list-counter;

    .list {
      margin-top: 1rem;
      list-style: none;
    }

    .list::before {
      margin-right: 1rem;
      content: counter(list-counter) ". "; /* Use the counter value */
      counter-increment: list-counter; /* Increment the counter */
      left: 0;
    }
  }
}
