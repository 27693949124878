.editProduct section {
  width: 100%;
  padding: 10px;
  min-height: calc(100vh - 80px);
  margin-bottom: 6rem;
  margin-top: 5rem;
  margin: 0;
}

.editProduct section .form_data {
  max-width: 700px;
  margin: 7rem auto;
  box-shadow: 0 0 15px -10px #2d3748;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .cross {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: -1rem;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    .cross-inner {
      height: 40px;
      width: fit-content;
      margin-right: 0rem;
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      display: flex;
      i {
        width: fit-content;
        padding: 3px;
        font-size: 20px;
      }
    }
    h4 {
      margin: 0;
      padding: 0;
    }
  }
}

.editProduct .form_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}

.editProduct .form_data h1 {
  font-size: 34px;
  color: #3873d1;
}

.editProduct .form_data p {
  color: #718096;
  font-size: 15px;
  margin-top: 9px;
}

.editProduct .form_data form {
  margin-top: 45px;
  width: 100%;
  padding: 10px 50px;
}

.editProduct .form_data form .form_input input {
  width: 100%;
  padding: 11px;
  border: 1px solid #d4d0d0;
  border-radius: 5px;
  outline: none;
  margin-bottom: 14px;
  margin-top: 9px;
  font-size: 14px;
}

.editProduct .form_data form .form_input label {
  font-weight: 600;
  font-size: 15px;
}

.editProduct .form_input .two {
  display: flex;
  align-items: center;
  position: relative;
}

.editProduct .form_data form .form_input input:focus {
  border: 2px solid rgba(66, 153, 225, 0.6);
}

.editProduct .form_data form .form_input input::placeholder {
  color: #8694a8;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.editProduct .form_input .showpass {
  position: absolute;
  right: 0;
  top: 1px;
  padding: 7px 10px;
  margin-right: 5px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #2a4365;
  background-color: #edf2f7;
  border-radius: 5px;
  cursor: pointer;
}

.editProduct form .btn {
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #3873d1;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.editProduct form .btn:hover {
  background-color: #97a1b1;
}

.editProduct .form_data form p {
  text-align: center;
  margin-top: 20px;
}

.editProduct .form_data form a {
  text-decoration: none;
  color: #718096;
  border-bottom: 2px solid #8694a8;
}

.editProduct section .cross {
  text-align: right;
  color: black;
  margin-right: -40rem;
  font-size: 1.8rem;
}
.editProduct .categoryName {
  width: 100%;
  padding: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.editProduct {
  input,select,textarea{
    border: 1px solid #d4d0d0;
    border-radius: 5px;
    outline: none;
    margin-bottom: 14px;
    margin-top: 9px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .editProduct {
    section {
      .form_data {
        width: 100%;
        .cross {
          margin-right: -1rem;
          text-align: left;
          width: 100%;
          .cross-inner {
            height: 40px;
            width: fit-content;
            background-color: black;
            margin-right: 0rem;
            border-radius: 3px;
            align-items: center;
            justify-content: center;
            display: flex;
            i {
              width: fit-content;
              padding: 3px;
              color: white;
            }
          }
        }
      }
    }
  }
  .editProduct .form_data form {
    padding: 10px 40px;
  }
}
