.div-found {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 9rem;
  width: 90%;
  margin: auto;
  .numb {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    h1 {
        padding: 0;
        margin: 0;
      font-size: 190px;
      -webkit-text-fill-color: #0000;
      -webkit-background-clip: text;
      background-clip: text;
      background-image: -webkit-linear-gradient(
        45deg,
        #0f2699 19.79%,
        #00aaff 84.88%
      );
    }
    h4 {
      font-size: 3rem;
    }
    .text {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 1.3rem;
        padding: 0;
        margin: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .div-found {
    .numb {
      h1 {
        font-size: 140px;
        padding: 0;
        margin: 0;
      }
      h4{
        font-size: 2rem;
      }
      .text {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
