.giftMain {
  padding: 1%;
  height: 90%;
  .giftingCard {
    border-radius: 7px;
    padding: 5px;
    background-color: white;
    width: 12rem;

    .giftImage {
      border-radius: 7px;
      background-color: #f0f0f0;
      height: 320px;
      img {
        max-width: 100%;
        padding: 20px;
        max-height: 100%;
        margin: auto;
      }
    }
    .giftText {
      .giftName {
        h5 {
          text-transform: capitalize;
          font-size: 29px;
          padding: 10px 15px 11px 0px;
          font-family: Oswald;
          color: #666666;
          font-weight: 600;
        }
      }
      .wrapper-div {
        margin: 0;
        padding: 0;
        .inner-wrapper {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: space-between;

          .wrapper {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5;
            .img-outer {
              width: 100%;
              margin: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              padding: 0px;
              gap: 5px;
              p {
                font-size: 12px;
                margin: 0;
                padding: 0;
              }
              img {
                // width: 0%;
                width: 28px;
              }
            }
          }
        }
      }
      .submit-btn {
        justify-content: center;
        display: flex;
        padding-bottom: 10px;
        padding-top: 16px;
        .view-more-btn {
          background-image: linear-gradient(
            -79deg,
            #351f99 0.36%,
            #0099e5 117.12%
          );
          width: 45%;
          border-radius: 9px;
          padding: 8px;
          color: white;
          border: none;
          font-size: 17px;
        }
      }
    }
  }
}
