.editBlog section {
    width: 100%;
    padding: 10px;
    min-height: calc(100vh - 80px);
    margin-bottom: 6rem;
    margin-top: 5rem;
    margin: 0;
  }
  
  .editBlog section .form_data {
    max-width: 700px;
    margin: 9rem auto;
    padding: 35px 10px;
    box-shadow: 0 0 15px -10px #2d3748;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cross {
      margin-left: 1rem;
      margin-right: -1rem;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      .cross-inner {
        height: 40px;
        width: fit-content;
        margin-right: 0rem;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        display: flex;
        i {
          width: fit-content;
          padding: 3px;
          font-size: 20px;
        }
      }
      h4{
        margin: 0;
        padding: 0;
      }
    }
  }
  
  .editBlog .form_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
  
  .editBlog .form_data h1 {
    font-size: 34px;
    color: #3873d1;
  }
  
  .editBlog .form_data p {
    color: #718096;
    font-size: 15px;
    margin-top: 9px;
  }
  
  .editBlog .form_data form {
    margin-top: 45px;
    width: 100%;
    padding: 10px 50px;
  }
  
  .editBlog .form_data form .form_input input {
    width: 100%;
    padding: 11px;
    border: 1px solid #d4d0d0;
    border-radius: 5px;
    outline: none;
    margin-bottom: 14px;
    margin-top: 9px;
    font-size: 14px;
  }
  
  .editBlog .form_data form .form_input label {
    font-weight: 600;
    font-size: 15px;
  }
  
  .editBlog .form_input .two {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .editBlog .form_data form .form_input input:focus {
    border: 2px solid rgba(66, 153, 225, 0.6);
  }
  
  .editBlog .form_data form .form_input input::placeholder {
    color: #8694a8;
    letter-spacing: 0.5px;
    font-size: 14px;
  }
  
  .editBlog .form_input .showpass {
    position: absolute;
    right: 0;
    top: 1px;
    padding: 7px 10px;
    margin-right: 5px;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #2a4365;
    background-color: #edf2f7;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .editBlog form .btn {
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #3873d1;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .editBlog form .btn:hover {
    background-color: #97a1b1;
  }
  
  .editBlog .form_data form p {
    text-align: center;
    margin-top: 20px;
  }
  
  .editBlog .form_data form a {
    text-decoration: none;
    color: #718096;
    border-bottom: 2px solid #8694a8;
  }
  
  .editBlog section .cross {
    text-align: right;
    color: black;
    margin-right: -40rem;
    font-size: 1.8rem;
  }
  .editBlog .categoryName {
    width: 100%;
    padding: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  @media (max-width: 767px) {
    .editBlog {
      section {
        .form_data {
          width: 100%;
          .cross {
            margin-right: -1rem;
            text-align: left;
            width: 100%;
            .cross-inner {
              height: 40px;
              width: fit-content;
              background-color: black;
              margin-right: 0rem;
              margin-top: 1rem;
              border-radius: 3px;
              align-items: center;
              justify-content: center;
              display: flex;
              i {
                width: fit-content;
                padding: 3px;
                color: white;
              }
            }
          }
        }
      }
    }
    .editBlog .form_data form {
      padding: 10px 40px;
    }
  }
  