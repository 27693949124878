.singleProduct-main {
  width: 100%;
  background-color: #fafbfe;
  .singleProduct-hero-img-section {
    background-image: url("../../assets/homehero.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    min-height: 600px;

    // .singleProduct-main-hero-content-section {
    //   padding: 8%;
    //   width: 100%;
    //   //   display: flex;
    //   //   justify-content: center;
    //   //   align-items: center;
    //   color: white;
    //   .singleProduct-hero-image {
    //     font-size: 4rem;
    //     font-weight: 700;
    //     display: flex;
    //     img {
    //       width: 40%;
    //       margin: auto;
    //     }
    //   }
    // }
  }
  .singleProduct-hero-image {
    margin-top: -500px;
    .singleProduct-hero-image-carousel {
      width: 80%;
      margin: auto;
      padding-top: 10rem;
      padding-bottom: 2rem;

      .section-1 {
        .outer {
          background-color: white;
          border-radius: 10px;
          height: 100%;
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);

          .inner {
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            // width: 10%;
            // margin: auto;
            padding: 2rem;
            .singleProduct-section-1 {
              .section-1-heading {
                h1 {
                  color: #000;
                  font-size: 33px;
                  margin-bottom: 2rem;
                }
              }
              .section-1-text {
                p {
                  color: #000;
                  font-size: 18px;
                  text-align: justify;
                }
              }
            }
            .singleProduct-section-2 {
              background-color: #f5f5f5;
              padding: 1rem;
              border-radius: 10px;
              .singleProduct-section-2-inner {
                .section-2-heading {
                  position: relative;
                  h5 {
                    padding-bottom: 3%;
                    -webkit-text-fill-color: #0000;
                    -webkit-background-clip: text;
                    background-clip: text;
                    background-image: -webkit-linear-gradient(
                      45deg,
                      #0f2699 19.79%,
                      #00aaff 84.88%
                    );
                  }
                  h5:after {
                    content: "";
                    height: 3px;
                    width: 6rem;
                    background-color: #ffb219;
                    position: absolute;
                    bottom: 8px;
                    left: 0px;
                  }
                }
                .section-2-subheading {
                  h6 {
                    color: #1c1b1b;
                  }
                  p {
                    color: #2e2d2d;
                    text-align: justify;
                    text-transform: capitalize;
                  }
                }
                .section-2-button {
                  button {
                    width: 100%;
                    background-color: black;
                    background-image: linear-gradient(
                      45deg,
                      #0f2699 19.79%,
                      #00aaff 84.88%
                    );
                    text-decoration: none;
                    color: white;
                    border-radius: 7px;
                    border: none;
                    padding: 2%;
                  }
                }
              }
            }
          }
        }
      }
      .section-2 {
        .outer {
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
          background-color: white;
          border-radius: 10px;
          height: 100%;
       
          .inner {
            
            .owl-outerr {
              position: relative;
              height: 100%;
              .img-outer{
                padding: 5rem 3rem;
                height: 100%;
                .img-inner{
                  height: 100%;
                background-color: #F5F5F5;
                border-radius: 10px;

                }
              }
              .owl-nav {
                position: absolute;
                width: 90%;
                left: 50%;
                top: 50%;
                transform: translatey(-150%) translateX(-50%);
                display: flex;
                justify-content: space-between;
                button {
                  transform: scale(2.5);
                }
              }
              .owl-dots{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .singleProduct-mid-section {
    width: 100%;
    padding: 2%;
    .singleProduct-mid-section-outer {
      background-color: #ffffff;
      box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      width: 80%;
      margin: auto;
      border-radius: 7px;
      margin-top: -9rem;
      .singleProduct-section-1 {
        padding: 2%;
        padding-left: 4%;
        .section-1-heading {
          h1 {
            text-transform: capitalize;
          }
        }
      }
      .singleProduct-section-2 {
        padding: 2%;
        .singleProduct-section-2-inner {
          display: flex;
          padding: 6%;
          flex-direction: column;
          row-gap: 5%;
          background-color: #f0f0f0;
          border-radius: 15px;
          .section-2-heading {
            position: relative;
            h5 {
              padding-bottom: 3%;
              -webkit-text-fill-color: #0000;
              -webkit-background-clip: text;
              background-clip: text;
              background-image: -webkit-linear-gradient(
                45deg,
                #0f2699 19.79%,
                #00aaff 84.88%
              );
            }
            h5:after {
              content: "";
              height: 3px;
              width: 6rem;
              background-color: #ffb219;
              position: absolute;
              bottom: 8px;
              left: 0px;
            }
          }
          .section-2-subheading {
            padding-top: 3%;
            h6 {
              color: #1c1b1b;
            }
            p {
              color: #2e2d2d;
              text-align: justify;
            }
          }
          .section-2-button {
            button {
              width: 100%;
              background-color: black;
              background-image: linear-gradient(
                45deg,
                #0f2699 19.79%,
                #00aaff 84.88%
              );
              text-decoration: none;
              color: white;
              border-radius: 7px;
              border: none;
              padding: 2%;
            }
          }
        }
      }
    }
  }
}

.butn-submit {
  width: 30%;
  border-radius: 6px;
  border: none;
  background-image: linear-gradient(-79deg, #351f99 0.36%, #0099e5 117.12%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5px;
  margin-top: 10px;
  outline: none;
}

.section-1-heading {
  h1 {
    text-transform: capitalize;
  }
}

@media (max-width: 767px) {
  .singleProduct-main {
    .singleProduct-hero-img-section {
      .singleProduct-main-hero-content-section {
      }
    }

    .singleProduct-hero-image {
      // padding-top: 3rem;
      img {
        width: 100%;
      }
      .singleProduct-hero-image-carousel {
        width: 95%;
        padding-top: 1rem;
        flex-direction: column-reverse;
        row-gap: 1rem;
        .section-1 {
          .outer {
            .inner {
              padding: 18px;
              .singleProduct-section-1 {
                .section-1-heading {
                  h1 {
                    font-size: 20px;
                  }
                }
                .section-1-text {
                  p {
                    font-size: 14px;
                  }
                }
              }
              .singleProduct-section-2 {
                .singleProduct-section-2-inner {
                  .section-2-heading {
                    h5 {
                      padding-bottom: 5%;
                      font-size: 18px;
                    }
                  }
                  .section-2-subheading {
                    h6 {
                      font-size: 14px;
                    }
                    p {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .singleProduct-mid-section {
      .singleProduct-mid-section-outer {
        margin: -3rem auto auto;
        width: 90%;
        .singleProduct-section-1 {
          padding: 4%;
          padding-bottom: 0% !important;

          .section-1-heading {
            h1 {
              font-size: 17px !important;
            }
          }
          .section-1-text {
            p {
              font-size: 12px;
              text-align: justify;
            }
          }
        }
        .singleProduct-section-2 {
          .singleProduct-section-2-inner {
            padding: 4%;
            .section-2-heading {
              h5 {
                font-size: 17px;
              }
            }
            .section-2-subheading {
              h6 {
                font-size: 15px;
              }
              p {
                font-size: 13px;
                text-align: justify;
              }
            }
          }
        }
      }
    }
  }
}
