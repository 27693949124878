.thank-you-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  //   background-color: #bdd7fd;
  .thank-you-message {
    text-align: center;
    font-family: Arial, sans-serif;
    animation: grow 3s ease-out forwards;
    .line {
      font-size: 0; /* Start with font size 0 */
      animation: textGrow 2s ease-out forwards;
      background: linear-gradient(90deg, #5f9fff, #5140e7, #7240e7);
      background-size: 100%;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .line:first-child {
      animation-delay: 0s;
    }

    .line:last-child {
      animation-delay: 0.5s;
    }

    @keyframes textGrow {
      from {
        font-size: 0;
      }
      to {
        font-weight: 900;
        font-size: 3rem;
      }
    }
  }
}

@keyframes grow {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
