.CustomMerchand {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  .header {
    height: 100px;
    width: 100%;
  }
  .heroSection {
    margin: auto;
    margin-top: 8.7rem;
    height: 100%;
    width: 93%;
    border-radius: 25px;
    display: flex;
    padding: 2rem 1rem;
    align-items: center;
    background-image: -webkit-linear-gradient(
      180deg,
      #0f2699 19.79%,
      #00aaff 84.88%
    );
    border: 4px solid orange;
    .img {
      height: 400px;
      width: 40%;
      width: 460px;
      img {
        width: 100%;
        height: 90%;
      }
    }
    .heroTitle {
      .brand {
        font-weight: bold;
        font-size: 4.9rem;
        color: white;
        @media (max-height: 1100px) {
          font-size: 3.5rem;
        }
      }
      p {
        color: rgb(255, 255, 255);
        text-align: center;
        font-weight: 500;
        font-size: 18px;
      }
    }
    .cta-buttons {
      text-align: center;
      margin-top: 3rem;
      button {
        margin-left: 1rem;
        padding: 10px 25px;
        border: none;
        background: linear-gradient(56.47deg, #e96701 0%, #faa805 100%);
        color: white;
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.211);
        border-radius: 8px;
      }
    }
  }

  // 2
  .uni-about {
    width: 93%;
    margin: 2rem auto;
    box-shadow: 0 0 30px 2px hsla(0, 0%, 50%, 50%);
    padding: 2rem;
    border-radius: 10px;
    .about-us {
      h2 {
        padding-bottom: 2rem;
        text-align: center;
        font-size: 45px;
        font-family: "Oswald";
        span {
          font-size: 45px;
          font-family: "Oswald";
          background: linear-gradient(45deg, #0f2699 19.79%, #00aaff 84.88%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
        }
      }
      p {
        color: black;
        font-weight: 400;
      }
    }
  }
  //   3
  .product-showcase-section {
    border-radius: 10px;
    margin: auto;
    width: 93%;
    box-shadow: 0 0 30px 2px hsla(0, 0%, 50%, 50%);

    margin-top: 3rem;
    padding: 3rem 0;

    h2 {
      font-size: 45px;
      font-family: "Oswald";
      text-align: center;
      background: linear-gradient(45deg, #0f2699 19.79%, #00aaff 84.88%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
    }
    .product-categories {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 15px;
      margin-top: 3rem;

      .product-category {
        padding: 1rem;

        width: 30%;
        &:hover {
          border-radius: 10px;
          box-shadow: 0 0 10px 2px hsla(0, 0%, 0%, 0.301);
        }
        h4 {
          font-size: 25px;
          line-height: normal;
          background: linear-gradient(45deg, #0f2699 19.79%, #00aaff 84.88%);

          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          width: fit-content;
        }
        p {
          line-height: 17px;
        }
        .overlay {
          margin: 0;
          padding: 0;
          display: flex;
          // gap: 20px;
          justify-content: space-between;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              // line-height: 18px;
              font-size: 18px;
              font-weight: 500;
            }
          }
          .image {
            width: 50%;
            height: 100%;
            text-align: end;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .category-buttons {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;

          button {
            margin-top: 10px;
            padding: 5px 20px;
            background: linear-gradient(56.47deg, #e96701 0%, #faa805 100%);
            box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.211);
            color: white;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }
  }

  // 4
  .sustainability-section {
    width: 93%;
    margin: 2rem auto;
    box-shadow: 0 0 30px 2px hsla(0, 0%, 50%, 50%);
    border-radius: 10px;
    display: flex;
    justify-content: start;
    align-items: start;

    .contentpara {
      padding: 1.5rem;

      width: 55%;

      h2 {
        padding-bottom: 1rem;
        font-size: 45px;
        font-family: "Oswald";
        background: linear-gradient(45deg, #0f2699 19.79%, #00aaff 84.88%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
      }
      ul {
        padding-left: 1rem;
      }
    }

    .image {
      width: 45%;
      // height: 100%;
      height: 330px;
      text-align: center;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // 5
  .customization-process-section {
    padding: 2rem 0;
    border-radius: 10px;
    margin: auto;
    width: 93%;
    box-shadow: 0 0 30px 2px hsla(0, 0%, 50%, 50%);

    h2 {
      font-size: 45px;
      font-family: "Oswald";
      width: fit-content;
      background: linear-gradient(45deg, #0f2699 19.79%, #00aaff 84.88%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      margin: 1rem auto;
    }

    .steps {
      padding: 0;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      border-radius: 10px;
      .step {
        padding: 10px;
        border-radius: 10px;
        width: 42%;
        height: 170px;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 2rem;
        &:hover {
          box-shadow: 0 0 30px 2px hsla(0, 0%, 50%, 50%);
        }
        img {
          padding: 10px;
          background: linear-gradient(45deg, #0f2699 19.79%, #00aaff 84.88%);
          border-radius: 50%;
          width: 30%;
          object-fit: fit-content;
        }
        .content {
          text-align: start;
        }
      }
    }
  }

  // 6
  .testimonial {
    .container-main {
      margin: 2rem;
      background-color: white;
      border-radius: 20px;
      padding-top: 1rem;
      .heading {
        width: fit-content;
        display: flex;
        align-items: center;
        margin: auto;
        justify-content: center;
        position: relative;
        h2 {
          font-size: 45px;
          font-family: Oswald;
          -webkit-text-fill-color: #0000;
          padding: 10px;
          width: fit-content;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );
        }
      }

      .key-points {
        .key-card {
          height: 280px;
          min-height: 250px;
          width: 100%;
          margin: auto;
          padding: 20px;
          .gapping {
            height: 100%;
            width: 90%;
            box-shadow: 0px 0px 10px 1px rgba(128, 128, 128, 0.196);
            margin: auto;
            border-radius: 15px;
            padding: 20px;
            text-align: center;
            column-gap: 15px;

            .card-upper-section {
              padding: 0;
              .card-upper-inner {
                max-height: 50px;
                max-width: 50px;
              }
            }
            .card-mid-section {
              padding: 0;
              .card-mid-inner {
                text-align: left;
                p {
                  font-size: 14px;
                  text-align: justify;
                }
              }
            }
            .card-bottom-section {
              padding: 0;
              .card-bottom-inner {
                display: flex;
                // justify-content: space-around;
                align-items: center;
                flex-direction: row;
                gap: 15px;
                .card-image {
                  img {
                    width: 45px;
                    height: 45px;
                    border-radius: 40px;
                  }
                }
                .card-name {
                  text-align: start;
                  .name-upper {
                    p {
                      -webkit-text-fill-color: #0000;
                      -webkit-background-clip: text;
                      background-clip: text;
                      background-image: -webkit-linear-gradient(
                        45deg,
                        #0f2699 19.79%,
                        #00aaff 84.88%
                      );
                      font-weight: bold;
                      padding-bottom: 0;
                      margin-bottom: 0;
                      font-size: 16px;
                    }
                  }
                  .name-down {
                    p {
                      font-size: 14px;
                      padding: 0;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            &:hover {
              transform: translate(0, 0) scale(1.03);
              box-shadow: 0px 0px 10px 1px rgba(128, 128, 128, 0.697);
            }
          }
        }
        // .owl-item:nth-child(3n) .gapping {
        //   background-color: #b3ff1a0f;
        // }
        // .owl-item:nth-child(3n + 1) .gapping {
        //   background-color: #1a8cff0f;
        // }
        // .owl-item:nth-child(3n + 2) .gapping {
        //   background-color: #5ab6710f;
        // }
        .owl-nav {
          position: absolute;
          width: 102%;
          left: -1%;
          top: 50%;
          transform: translatey(-150%);
          display: flex;
          justify-content: space-between;
          button {
            transform: scale(2.5);
            &:hover {
              background-color: transparent;
              transform: translate(0, 0) scale(3);
              color: black;
            }
          }
        }
      }
    }
  }

  .accordion {
    margin: 4rem;
  }
}

@media (max-width: 1024px) {
  .CustomMerchand {
    .heroSection {
      padding: 1rem;
      align-items: center;

      .img {
        height: 350px;
        width: 40%;
        width: 380px;
        img {
          width: 100%;
          height: 90%;
        }
      }
      .heroTitle {
        .brand {
          font-size: 3.3rem;
        }
      }
      .cta-buttons {
        margin-top: 3rem;
        button {
          padding: 10px 25px;
        }
      }
    }

    // 2
    .uni-about {
      margin: 2rem auto;
      padding: 1.5rem;
      .about-us {
        h2 {
          padding-bottom: 1rem;

          font-size: 30px;

          span {
            font-size: 30px;
          }
        }
      }
    }
    //   3
    .product-showcase-section {
      margin-top: 1.5rem;
      padding: 1rem 0;

      h2 {
        font-size: 30px;
      }
      .product-categories {
        justify-content: space-evenly;
        gap: 15px;
        margin-top: 1.1rem;

        .product-category {
          padding: 1rem;

          width: 30%;

          h4 {
            font-size: 20px;
          }
          p {
            font-size: 14px;
            line-height: 15px;
          }
          .overlay {
            justify-content: space-between;

            ul {
              li {
                font-size: 15px;
                line-height: 17px;
              }
            }
            .image {
              width: 50%;
              height: 100%;

              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          .category-buttons {
            display: flex;
            button {
              margin-top: 2px;
              padding: 2px 10px;
              font-size: 13px;
            }
          }
        }
      }
    }

    // 4
    .sustainability-section {
      width: 93%;
      margin: 1.5rem auto;

      .contentpara {
        padding: 1.5rem;
        width: 55%;

        h2 {
          padding-bottom: 5px;
          font-size: 30px;
        }
        p {
          line-height: normal;
        }
        ul {
          padding-left: 1rem;
          li {
            line-height: normal;
          }
        }
      }

      .image {
        width: 45%;
        height: auto;
      }
    }

    // 5
    .customization-process-section {
      padding: 1.5rem 0;

      h2 {
        font-size: 35px;
      }

      .steps {
        padding: 0;

        .step {
          padding: 10px;

          width: 42%;
          height: 170px;
          display: flex;

          gap: 2rem;

          img {
            width: 30%;
            object-fit: fit-content;
          }
          .content {
            text-align: start;
          }
        }
      }
    }
    //
    .testimonial {
      .container-main {
        padding-top: 0;
        width: 95%;
        .key-points {
          padding: 0;
          .key-card {
            .gapping {
              box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.496);

              .card-upper-section {
                padding: 0;
                .card-upper-inner {
                  max-height: 50px;
                  max-width: 50px;
                }
              }
              .card-mid-section {
                padding: 0;
                .card-mid-inner {
                  text-align: start;
                  p {
                    margin-bottom: 0;
                    font-size: 14px;
                    text-align: justify;
                  }
                }
              }
              .card-bottom-section {
                .card-bottom-inner {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  gap: 10px;
                  .card-image {
                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 50px;
                    }
                  }
                  .card-name {
                    text-align: left;
                    .name-upper {
                      p {
                        -webkit-text-fill-color: #0000;
                        -webkit-background-clip: text;
                        background-clip: text;
                        background-image: -webkit-linear-gradient(
                          45deg,
                          #0f2699 19.79%,
                          #00aaff 84.88%
                        );
                        font-weight: bold;
                        padding-bottom: 0;
                        margin-bottom: 0;
                      }
                      .name-down {
                        text-align: start;
                        p {
                          float: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .CustomMerchand {
    .heroSection {
      margin-top: 12rem;
      padding: 1rem 10px;

      .img {
        height: auto;
        width: 40%;
        width: 50%;
        img {
          width: 100%;
          height: 90%;
        }
      }
      .heroTitle {
        .brand {
          font-size: 2.1rem;
        }
      }
      .cta-buttons {
        margin-top: 1rem;
        button {
          font-size: 14px;
          border-radius: 5px;
          padding: 4px 15px;
        }
      }
    }

    // 2
    .uni-about {
      margin: 2rem auto;
      padding: 1.5rem;
      .about-us {
        h2 {
          padding-bottom: 1rem;

          font-size: 30px;

          span {
            font-size: 30px;
          }
        }
      }
    }
    //   3
    .product-showcase-section {
      margin-top: 1.5rem;
      padding: 1rem 0;

      h2 {
        font-size: 30px;
      }
      .product-categories {
        justify-content: space-evenly;
        gap: 15px;
        margin-top: 1.1rem;

        .product-category {
          padding: 1rem;

          width: 30%;

          h4 {
            font-size: 20px;
          }
          p {
            font-size: 14px;
            line-height: 15px;
          }
          .overlay {
            justify-content: space-between;

            ul {
              li {
                font-size: 15px;
                line-height: 17px;
              }
            }
            .image {
              width: 50%;
              height: 100%;

              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          .category-buttons {
            display: flex;
            button {
              margin-top: 2px;
              padding: 2px 10px;
              font-size: 13px;
            }
          }
        }
      }
    }

    // 4
    .sustainability-section {
      width: 93%;
      margin: 1.5rem auto;

      .contentpara {
        padding: 1.5rem;
        width: 55%;

        h2 {
          padding-bottom: 5px;
          font-size: 30px;
        }
        p {
          line-height: normal;
        }
        ul {
          padding-left: 1rem;
          li {
            line-height: normal;
          }
        }
      }

      .image {
        width: 45%;
        height: auto;
      }
    }

    // 5
    .customization-process-section {
      padding: 1.5rem 0;

      h2 {
        font-size: 35px;
      }

      .steps {
        padding: 0;

        .step {
          padding: 10px;

          width: 42%;
          height: 170px;
          display: flex;

          gap: 2rem;

          img {
            width: 30%;
            object-fit: fit-content;
          }
          .content {
            text-align: start;
          }
        }
      }
    }
    //
    .testimonial {
      .container-main {
        padding-top: 0;
        width: 95%;
        .key-points {
          padding: 0;
          .key-card {
            .gapping {
              box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.496);

              .card-upper-section {
                padding: 0;
                .card-upper-inner {
                  max-height: 50px;
                  max-width: 50px;
                }
              }
              .card-mid-section {
                padding: 0;
                .card-mid-inner {
                  text-align: start;
                  p {
                    margin-bottom: 0;
                    font-size: 14px;
                    text-align: justify;
                  }
                }
              }
              .card-bottom-section {
                .card-bottom-inner {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  gap: 10px;
                  .card-image {
                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 50px;
                    }
                  }
                  .card-name {
                    text-align: left;
                    .name-upper {
                      p {
                        -webkit-text-fill-color: #0000;
                        -webkit-background-clip: text;
                        background-clip: text;
                        background-image: -webkit-linear-gradient(
                          45deg,
                          #0f2699 19.79%,
                          #00aaff 84.88%
                        );
                        font-weight: bold;
                        padding-bottom: 0;
                        margin-bottom: 0;
                      }
                      .name-down {
                        text-align: start;
                        p {
                          float: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .CustomMerchand {
    .heroSection {
      margin-top: 12rem;
      padding: 1rem 10px;
      display: flex;
      flex-direction: column;
      @media (max-width:550px) {
        margin-top: 8rem;
        
      }

      .img {
        height: auto;
        width: 40%;
        width: 50%;
        img {
          width: 100%;
          height: 90%;
        }
      }
      .heroTitle {
        .brand {
          font-size: 2.1rem;
        }
      }
      .cta-buttons {
        margin-top: 1rem;
        button {
          font-size: 14px;
          border-radius: 5px;
          padding: 4px 15px;
        }
      }
    }

    // 2
    .uni-about {
      margin: 2rem auto;
      padding: 1.5rem;
      .about-us {
        h2 {
          padding-bottom: 1rem;

          font-size: 30px;

          span {
            font-size: 30px;
          }
        }
      }
    }
    //   3
    .product-showcase-section {
      margin-top: 1.5rem;
      padding: 1rem 0;

      h2 {
        font-size: 30px;
      }
      .product-categories {
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 15px;
        margin-top: 1.1rem;

        .product-category {
          padding: 1rem;
          width: 48%;
          @media (max-width:400px) {
            width: 90%;
            
          }
          h4 {
            font-size: 20px;
          }
          p {
            font-size: 14px;
            line-height: 15px;
          }
          .overlay {
            justify-content: space-between;

            ul {
              li {
                font-size: 15px;
                line-height: 17px;
              }
            }
            .image {
              width: 50%;
              height: 100%;

              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          .category-buttons {
            display: flex;
            justify-content: space-evenly;
            button {
              margin-top: 2px;
              padding: 3px 8px;
              font-size: 12px;
              border-radius: 5px;
            }
          }
        }
      }
    }

    // 4
    .sustainability-section {
      width: 93%;
      margin: 1.5rem auto;
      display: flex;
      flex-direction: column;

      .contentpara {
        padding: 1.5rem;
        width: 100%;

        h2 {
          padding-bottom: 5px;
          font-size: 30px;
        }
        p {
          line-height: normal;
        }
        ul {
          padding-left: 1rem;
          li {
            line-height: normal;
          }
        }
      }

      .image {
        width: 80%;
        height: auto;
        margin: auto;
      }
    }

    // 5
    .customization-process-section {
      padding: 1.5rem 0;

      h2 {
        font-size: 35px;
      }

      .steps {
        padding: 0;
        justify-content: space-evenly;
        .step {
          padding: 10px;
          display: flex;
          flex-direction: column;
          width: 42%;
          height: 170px;
          display: flex;

          gap: 10px;
          margin-bottom: 10px;

          img {
            width: 50%;
            object-fit: fit-content;
          }
          .content {
            text-align: start;
            h5{
              font-size: 18px;
             margin: 0;
            }
            p{
              line-height: normal;
              font-size: 14px;
            }
          }
        }
      }
    }
    //
    .testimonial {
      .container-main {
        padding-top: 0;
        width: 95%;
        .key-points {
          padding: 0;
          .key-card {
            .gapping {
              box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.496);

              .card-upper-section {
                padding: 0;
                .card-upper-inner {
                  max-height: 50px;
                  max-width: 50px;
                }
              }
              .card-mid-section {
                padding: 0;
                .card-mid-inner {
                  text-align: start;
                  p {
                    margin-bottom: 0;
                    font-size: 14px;
                    text-align: justify;
                  }
                }
              }
              .card-bottom-section {
                .card-bottom-inner {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  gap: 10px;
                  .card-image {
                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 50px;
                    }
                  }
                  .card-name {
                    text-align: left;
                    .name-upper {
                      p {
                        -webkit-text-fill-color: #0000;
                        -webkit-background-clip: text;
                        background-clip: text;
                        background-image: -webkit-linear-gradient(
                          45deg,
                          #0f2699 19.79%,
                          #00aaff 84.88%
                        );
                        font-weight: bold;
                        padding-bottom: 0;
                        margin-bottom: 0;
                      }
                      .name-down {
                        text-align: start;
                        p {
                          float: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
