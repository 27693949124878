.customisedTshirt {
  .contact-hero-img-section {
    background-image: url("../../assets/homehero.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .contact-main-hero-content-section {
      padding: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      .contact-content {
        padding-top: 4%;
        font-size: 4rem;
        font-weight: 700;
      }
    }
  }
  .wrapper {
    padding-top: 3%;
    padding-bottom: 10%;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .divTshirt {
      width: 90%;
      .heading-tshirt {
        -webkit-text-fill-color: #0000;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: -webkit-linear-gradient(
          45deg,
          #0f2699 19.79%,
          #00aaff 84.88%
        );
        font-weight: 630;
        font-size: 30px;
        width: 100%;
        margin: auto;
        text-align: center;
        padding-bottom: 2rem;
        p {
          font-family: "bootstrap-icons";
        }
      }
    }
    .contact-text {
      h2 {
        padding-bottom: 2rem;
      }
    }
  }
  .text-center pb-10 {
    width: 35%;
    margin: auto;
  }
  .Container-1 {
    row-gap: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 4rem;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
    width: 90%;
    .Personal {
    }
    .name {
      padding-top: 15px;
      h6 {
        font-weight: 300;
      }
    }
    .theme {
      .design-theme {
        padding-top: 8px;
        padding-bottom: 8px;
        h6 {
          font-weight: 300;
        }

        .design {
          display: flex;
          gap: 10px;
        }
        .yes-input {
          margin-top: 0.4rem;
        }
      }

      .t-shirt {
        padding-top: 15px;
        padding-bottom: 15px;
        h6 {
          padding-bottom: 0.4rem;
          font-weight: 300;
        }
      }
    }
  }

  .input {
    width: 100%;
    text-decoration: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
  }

  input {
    padding: 10px;
    border-radius: 10px;
    outline: 0;
    border: 1px solid rgb(222, 206, 206);
    background-color: #f7f7f7;
    &:focus {
      // border: 0;
      outline: 0;
    }
  }

  .submit-button {
    width: 90%;
    padding-top: 8%;
    cursor: pointer;
    .button {
      background-image: linear-gradient(-79deg, #351f99 0.36%, #0099e5 117.12%);
      width: 21%;
      border-radius: 10px;
      border: none;
      color: white;
      padding: 6px;
      font-size: 20px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    border-radius: 1rem;
  }

  .purpose {
    padding: 0px;
    .purpose-2 {
      padding: 0px;
    }
    .t-shirt {
      padding: 0px;
      h6 {
        font-weight: 300;
      }
    }
  }
  .style-preference {
    padding: 0px;
    .tg {
      padding: 0px;
    }
    .t-shirt {
      padding: 0px;
      padding-top: 15px;
      h6 {
        padding-bottom: 0.4rem;

        font-weight: 300;
      }
    }
  }
}

@media (max-width: 767px) {
  .customisedTshirt {
    input {
      padding: 5px;
      border-radius: 5px;
    }
    .contact-hero-img-section {
      .contact-main-hero-content-section {
        text-align: center;
        .contact-content {
          padding-top: 3rem;
          font-size: 3rem;
        }
      }
    }
    .wrapper {
      .Container-1 {
        padding: 1rem 1.4rem;
        .name {
          padding-top: 8px;
          padding-bottom: 8px;
          h6 {
            font-size: 14px;
            padding-bottom: 0.4rem;
          }
        }
        .purpose {
          --bs-gutter-x: 0rem;
          .purpose-2 {
            font-size: 18px;
          }
          .t-shirt {
            padding-top: 8px;
            padding-bottom: 8px;
            h6 {
              padding-bottom: 0.4rem;
              font-size: 14px;
            }
          }
        }
        .theme {
          .design-theme {
            padding-top: 8px;
            padding-bottom: 8px;
            h6 {
              font-size: 14px;
            }
            label {
              font-size: 14px;
            }
            .design {
              display: flex;
              gap: 10px;
            }
            .yes-input {
              margin-top: 0.4rem;
            }
          }
          h3 {
            font-size: 18px;
          }
          .t-shirt {
            padding-top: 8px;
            padding-bottom: 8px;
            h6 {
              padding-bottom: 0.4rem;
              font-size: 14px;
            }
          }
        }
        .Personal {
          padding-top: 8px;
          padding-bottom: 8px;
          h3 {
            font-size: 18px;
          }
          h6 {
            padding-top: 15px;
          }
        }

        .style-preference {
          h3 {
            font-size: 18px;
          }
          .t-shirt {
            padding-top: 8px;
            padding-bottom: 8px;
            h6 {
              padding-bottom: 0.4rem;

              font-size: 14px;
            }
          }
        }
        .quantity {
          h4 {
            font-size: 18px;
          }
          .t-shirt {
            padding-top: 8px;
            padding-bottom: 8px;
            h6 {
              font-size: 14px;
            }
          }
        }
      }
    }
    .submit-button {
      button {
        width: 50% !important;
        border: none;
      }
    }
    .wrapper {
      .divTshirt {
        .heading-tshirt {
          font-size: 22px;
        }
      }
    }
  }
}
